<template>
    <v-container fluid>
        <v-card>
            <Page-Header
                :title="$t('reports.itemsSalesReport')"
                icon="mdi-credit-card-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="print"
                    >
                        <v-icon>
                            mdi-printer-outline
                        </v-icon>
                        {{ $t("print") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-checkbox
                                            class="mt-1"
                                            v-model="showAll"
                                            :label="
                                                $t('reports.withoutTimePeriod')
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-autocomplete
                                            v-model="selecteditemTypes"
                                            :items="itemTypes"
                                            hide-details
                                            item-text="itemTypeName"
                                            item-value="itemTypeId"
                                            :label="$t('item.itemType')"
                                            chips
                                            outlined
                                            dense
                                            deletable-chips
                                            multiple
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            :rules="rules"
                                            persistent-hint
                                            v-model="fiscalYear"
                                            :items="fiscalYears"
                                            item-text="title"
                                            item-value="fiscalYearGuid"
                                            :label="$t('fiscalYear.fiscalYear')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-autocomplete
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="stockGuid"
                                            :items="stocks"
                                            item-text="stockName"
                                            item-value="stockGuid"
                                            :label="$t('stock.stock')"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                            v-model="selectedItemGroups"
                                            :items="itemGroups"
                                            hide-details
                                            item-text="itemName"
                                            item-value="itemGuid"
                                            :label="$t('item.isGroup')"
                                            chips
                                            outlined
                                            dense
                                            deletable-chips
                                            multiple
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeCurrency"
                                            persistent-hint
                                            v-model="currencyGuid"
                                            :items="currencies"
                                            item-text="currencySymbol"
                                            item-value="currencyGuid"
                                            :label="$t('voucher.currency')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-autocomplete
                                            v-model="selectedBillTypes"
                                            :items="billSettings"
                                            hide-details
                                            item-text="billCaption"
                                            item-value="billSettingGuid"
                                            :label="$t('bill.billType')"
                                            chips
                                            outlined
                                            dense
                                            deletable-chips
                                            multiple
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="refreshTable"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                :items="items"
                class="pa-5"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template slot="body.append">
                    <tr>
                        <th>{{ $t("total") }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            {{ totalSoldQuantity }}
                        </th>
                        <th>
                            {{ totalSoldAmount | currency("", 3) }}
                        </th>
                        <th>
                            {{ totalReturnQuantity }}
                        </th>
                        <th>
                            {{ totalReturnAmount | currency("", 3) }}
                        </th>
                        <th>
                            {{ remainingQuantity }}
                        </th>
                        <th>
                            {{ remainingAmount | currency("", 3) }}
                        </th>
                    </tr>
                </template>
                <template v-slot:[`item.createdDate`]="{ item }">
                    {{ item.createdDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.soldTotalAmount`]="{ item }">
                    {{ item.soldTotalAmount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.saleReturnTotalAmount`]="{ item }">
                    {{
                        item.saleReturnTotalAmount
                            | currency("", floatingPoints)
                    }}
                </template>
                <template v-slot:[`item.remainingAmount`]="{ item }">
                    {{ item.remainingAmount | currency("", floatingPoints) }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            stocks: [],
            stockGuid: null,
            floatingPoints: 0,
            showAll: false,
            panel: [0, 1],
            fiscalYears: [],
            fiscalYear: null,
            remainingAmount: null,
            remainingQuantity: null,
            totalReturnAmount: null,
            totalReturnQuantity: null,
            totalSoldAmount: null,
            totalSoldQuantity: null,
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            loading: true,
            dialog: false,
            editedIndex: -1,
            currencyGuid: null,
            selecteditemTypes: null,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            headers: [
                {
                    text: this.$t("item.itemCode"),
                    value: "itemCode"
                },
                {
                    text: this.$t("item.itemName"),
                    value: "itemName"
                },
                {
                    text: this.$t("item.description"),
                    value: "description"
                },
                {
                    text: this.$t("item.company"),
                    value: "company"
                },
                {
                    text: this.$t("item.isGroup"),
                    value: "groupName"
                },
                {
                    text: this.$t("item.createdDate"),
                    value: "createdDate"
                },
                {
                    text: this.$t("item.soldQuantity"),
                    value: "soldQuantity"
                },
                {
                    text: this.$t("item.soldTotalAmount"),
                    value: "soldTotalAmount"
                },
                {
                    text: this.$t("item.saleReturnQuantity"),
                    value: "saleReturnQuantity"
                },
                {
                    text: this.$t("item.saleReturnTotalAmount"),
                    value: "saleReturnTotalAmount"
                },
                {
                    text: this.$t("item.remainingQuantity"),
                    value: "remainingQuantity"
                },
                {
                    text: this.$t("bill.remainingAmount"),
                    value: "remainingAmount"
                }
            ],
            selectedBillTypes: [],
            items: [],
            itemGroups: [],
            selectedItemGroups: [],
            billSettings: [],
            billSettingTypes: [],
            currencies: [],
            itemTypes: [],
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getFilterData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    methods: {
        close() {
            this.dialog = false;
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        refreshTable() {
            if (this.$refs.form.validate()) {
                if (
                    this.selecteditemTypes == null ||
                    this.selecteditemTypes.length == 0
                )
                    this.selecteditemTypes = this.itemTypes.map(
                        a => a.itemTypeId
                    );

                if (
                    this.selectedItemGroups == null ||
                    this.selectedItemGroups.length == 0
                )
                    this.selectedItemGroups = this.itemGroups.map(
                        a => a.itemGuid
                    );

                if (
                    this.selectedBillTypes == null ||
                    this.selectedBillTypes.length == 0
                )
                    this.selectedBillTypes = this.billSettings.map(
                        a => a.billSettingGuid
                    );

                var defaultFiscalYear =
                    this.fiscalYear == null
                        ? this.fiscalYears.filter(function(obj) {
                              return obj.isActive == true;
                          })[0].fiscalYearGuid
                        : this.fiscalYear;

                axios
                    .get("Items/ItemsSalesReport", {
                        params: {
                            currencyGuid:
                                this.currencyGuid == null
                                    ? this.currencies.filter(function(obj) {
                                          return obj.isMain == true;
                                      })[0].currencyGuid
                                    : this.currencyGuid,
                            fiscalYearGuid: defaultFiscalYear,
                            selectedItemTypes: this.selecteditemTypes,
                            selectedBillTypes: this.selectedBillTypes,
                            selectedItemGroups: this.selectedItemGroups,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            showAll: this.showAll,
                            stockGuid: this.stockGuid
                        }
                    })
                    .then(response => {
                        this.items = response.data.data;

                        this.totalSoldQuantity =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) => a + (b["soldQuantity"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;

                        this.totalSoldAmount =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) => a + (b["soldTotalAmount"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;

                        this.totalReturnQuantity =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) =>
                                        a + (b["saleReturnQuantity"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;

                        this.totalReturnAmount =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) =>
                                        a + (b["saleReturnTotalAmount"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;

                        this.remainingQuantity =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) => a + (b["remainingQuantity"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;

                        this.remainingAmount =
                            Math.round(
                                (this.items.reduce(
                                    (a, b) => a + (b["remainingAmount"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dialog = false;
                    });
            }
        },
        getFilterData() {
            this.loading = true;
            axios
                .get("Items/GetFilterData")
                .then(response => {
                    this.itemTypes = response.data.data.itemTypes;
                    this.itemGroups = response.data.data.itemGroups;
                    this.billSettings = response.data.data.billSettings.map(
                        item => {
                            return {
                                billSettingGuid: item.billSettingGuid,
                                billCaption: this.$t(
                                    "billTypes." + item.billType
                                )
                            };
                        }
                    );
                    this.selectedBillTypes = this.billSettings.map(
                        a => a.billSettingGuid
                    );

                    this.currencies = response.data.data.currencies;
                    this.stocks = response.data.data.stocks;
                    this.fiscalYears = response.data.data.fiscalYears;
                    this.fiscalYear = this.fiscalYears.filter(function(obj) {
                        return obj.isActive == true;
                    })[0].fiscalYearGuid;
                    this.currencyGuid = this.currencies.filter(function(obj) {
                        return obj.isMain == true;
                    })[0].currencyGuid;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        print() {
            var defaultFiscalYear =
                this.fiscalYear == null
                    ? this.fiscalYears.filter(function(obj) {
                          return obj.isActive == true;
                      })[0].fiscalYearGuid
                    : this.fiscalYear;

            axios({
                url: "Items/PrintItemsSalesReport",
                method: "GET",
                responseType: "blob",
                params: {
                    fiscalYearGuid: defaultFiscalYear,
                    currencyGuid:
                        this.currencyGuid == null
                            ? this.currencies.filter(function(obj) {
                                  return obj.isMain == true;
                              })[0].currencyGuid
                            : this.currencyGuid,
                    selectedItemTypes: this.selecteditemTypes,
                    selectedBillTypes: this.selectedBillTypes,
                    selectedItemGroups: this.selectedItemGroups,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    showAll: this.showAll
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تقرير مبيعات المواد.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        changeCurrency(val) {
            this.floatingPoints = this.currencies.find(
                x => x.currencyGuid == val
            ).floatingPoints;
        }
    }
};
</script>
